const SaveLoad = ({ vita, mana, desiredMana, desiredVita, dh, curse, sleep, ac, loadStats }) => {
  const save = () => {
    if (window && window.localStorage) {
      const stats = { vita, mana, desiredVita, desiredMana, curse, dh, sleep, ac }

      window.localStorage.setItem('stats', JSON.stringify(stats))
    } else {
      alert('local storage not supported')
    }
  }

  const load = () => {
    if (window && window.localStorage && window.localStorage.getItem('stats')) {
      return loadStats(JSON.parse(window.localStorage.getItem('stats')))
    } else {
      alert('local storage not supported')
    }
  }

  return (
    <div>
      <button onClick={save} style={{ margin: '1em 2em' }}>
        {'Save settings to local storage'}
      </button>
      <button onClick={load} style={{ margin: '1em 2em' }}>
        {'Load settings from local storage'}
      </button>
    </div>
  )
}

export default SaveLoad
