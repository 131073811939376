import React from 'react'

export default function Stats({
  mana,
  vita,
  desiredMana,
  desiredVita,
  handleDesiredMana,
  handleDesiredVita,
  handleMana,
  handleVita,
}) {
  return (
    <div className="stats">
      <div style={{ marginRight: 30, fontSize: 12 }}>
        <h4 className={'title'}>{'Current Vita'}</h4>
        <input className="vita-input" value={vita} onChange={handleVita} />
        <h4 className={'title'}>{'Current Mana'}</h4>
        <input className="mana-input" value={mana} onChange={handleMana} />
      </div>
      <div style={{ fontSize: 12 }}>
        <h4 className={'title'}>{'Desired Vita'}</h4>
        <input className="vita-input" value={desiredVita} onChange={handleDesiredVita} />
        <h4 className={'title'}>{'Desired Mana'}</h4>
        <input className="mana-input" value={desiredMana} onChange={handleDesiredMana} />
      </div>
    </div>
  )
}
