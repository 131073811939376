import React, { useEffect, useState } from 'react'
import OtherLinks from './OtherLinks'
import Output from './Output'
import Stats from './Stats'
import Target from './Target'
import EXP from './Experience'
import References from './References'
import SaveLoad from './SaveLoad'

export default function App() {
  const [ac, setAc] = useState(-76),
    [computedAc, setComputedAc] = useState(ac),
    [vita, setVita] = useState('0'),
    [mana, setMana] = useState('0'),
    [desiredVita, setDesiredVita] = useState('0'),
    [desiredMana, setDesiredMana] = useState('0'),
    [vitaExp, setVitaExp] = useState('0'),
    [manaExp, setManaExp] = useState('0'),
    [desiredVitaExp, setDesiredVitaExp] = useState('0'),
    [desiredManaExp, setDesiredManaExp] = useState('0'),
    [curse, setCurse] = useState({ snare: false, vex: false, sc: false, suppress: false }),
    [dh, setDh] = useState({ ee: false, sam: false, sa: false }),
    [sleep, setSleep] = useState(false)

  const curseMapper = (curse) => {
    if (curse === 'snare') {
      return 20
    } else if (curse === 'vex') {
      return 30
    } else if (curse === 'sc') {
      return 50
    } else if (curse === 'suppress') {
      return 55
    }
  }

  const dhMapper = (curse) => {
    if (curse === 'ee') {
      return 6
    } else if (curse === 'sam') {
      return 9
    } else if (curse === 'sa') {
      return 12
    }
  }

  useEffect(() => {
    const hasCurse = Object.values(curse).some(Boolean)
    const hasDh = Object.values(dh).some(Boolean)

    let adjustedAc = Number(ac)

    if (hasCurse) {
      adjustedAc = adjustedAc + curseMapper(Object.entries(curse).filter((c) => c[1])[0][0])
    }

    if (hasDh) {
      adjustedAc = adjustedAc + dhMapper(Object.entries(dh).filter((c) => c[1])[0][0])
    }

    setComputedAc(adjustedAc ? adjustedAc : 0)
  }, [ac, curse, dh])

  const resetFields = () => {
    setAc(-76)

    setSleep(false)

    setCurse({ snare: false, vex: false, sc: false, suppress: false })

    setDh({ ee: false, sam: false, sa: false })
  }

  function calculateUnitsCost(completeSegments, incompleteSegment) {
    let totalExp = 0

    for (let i = 0; i <= completeSegments; i++) {
      const unitCost = 20 + (i + 1) * 2

      if (i === completeSegments) {
        totalExp += unitCost * incompleteSegment
      } else {
        totalExp += unitCost * 20
      }
    }

    return totalExp * 10
  }

  // From 0 to 100k, 1 vita unit (100) costs 20m. From 100k to 120k, 1 vita unit costs 22m.
  // From 120k, 1 vita unit costs 24m. As vita grows by 20k, the cost of 1 vita
  // unit increases by 2m.
  const calculateExpForVita = (value) => {
    const vitaUnit = 100
    // Every 20k after 100k is 1 "complete segment" (20000k vita / vitaUnit = 200 sells)
    // Subtract 5 segments that make up 100k vita (20k * 5)
    const completeSegments = Math.floor(value / 20000 - 5)
    // calculate the size of the incomplete segment. (1 segment is 20k vita)
    const incompleteSegment = (value % 20000) / 1000
    const totalCostAfter100k = calculateUnitsCost(completeSegments, incompleteSegment)

    if (value <= 100000) {
      // 20m = cost of one vita unit under 100k
      const costUntil100k = (value / vitaUnit) * 20

      return costUntil100k
    } else if (value > 100000) {
      // 100k vita costs 20bil (20000m)
      const costOf100kVita = 20000

      return costOf100kVita + totalCostAfter100k
    } else {
      return null
    }
  }

  const calculateExpForMana = (value) => {
    const manaUnit = 50
    // Every 10k after 50k is 1 "complete segment"
    const completeSegments = Math.floor(value / 10000 - 5)
    const incompleteSegment = (value % 10000) / 500
    const totalCostAfter50k = calculateUnitsCost(completeSegments, incompleteSegment)

    if (value <= 50000) {
      const manaUntil50k = (value / manaUnit) * 20

      return manaUntil50k
    } else if (value > 50000) {
      const costOf50kMana = 20000

      return costOf50kMana + totalCostAfter50k
    } else {
      return null
    }
  }

  const handleVita = (value) => {
    if (value && Number(value) > 999999999) {
      setVita('999999999')

      setVitaExp(calculateExpForVita(999999999))

      return
    }

    setVita(value.replace(/[^\d,]+/g, ''))

    setVitaExp(calculateExpForVita(Number(value)))
  }

  const handleMana = (value) => {
    if (value && Number(value) > 999999999) {
      setMana('999999999')

      setManaExp(calculateExpForMana(999999999))

      return
    }

    setMana(value.replace(/[^\d,]+/g, ''))

    setManaExp(calculateExpForMana(Number(value)))
  }

  const handleDesiredVita = (value) => {
    if (value && Number(value) > 999999999) {
      setDesiredVita('999999999')

      setDesiredVitaExp(calculateExpForVita(999999999))

      return
    }

    setDesiredVita(value.replace(/[^\d,]+/g, ''))

    setDesiredVitaExp(calculateExpForVita(Number(value)))
  }

  const handleDesiredMana = (value) => {
    if (value && Number(value) > 999999999) {
      setDesiredMana('999999999')

      setDesiredManaExp(calculateExpForMana(999999999))

      return
    }

    setDesiredMana(value.replace(/[^\d,]+/g, ''))

    setDesiredManaExp(calculateExpForMana(Number(value)))
  }

  const withSleep = () => (sleep ? 1.3 : 1)

  const loadStats = (stats) => {
    const { vita, mana, desiredVita, desiredMana, curse, dh, sleep, ac } = stats

    setVita(vita)

    setMana(mana)

    setDesiredMana(desiredMana)

    setDesiredVita(desiredVita)

    setCurse(curse)

    setDh(dh)

    setSleep(sleep)

    setAc(ac)
  }

  return (
    <div className="App">
      <OtherLinks />
      <SaveLoad
        vita={vita}
        mana={mana}
        desiredMana={desiredMana}
        desiredVita={desiredVita}
        loadStats={loadStats}
        curse={curse}
        dh={dh}
        sleep={sleep}
        ac={ac}
      />
      <EXP
        vitaExp={vitaExp}
        manaExp={manaExp}
        desiredVitaExp={desiredVitaExp}
        desiredManaExp={desiredManaExp}
      />
      <Stats
        vita={vita}
        mana={mana}
        desiredVita={desiredVita}
        desiredMana={desiredMana}
        handleVita={(e) => handleVita(e.target.value)}
        handleMana={(e) => handleMana(e.target.value)}
        handleDesiredVita={(e) => handleDesiredVita(e.target.value)}
        handleDesiredMana={(e) => handleDesiredMana(e.target.value)}
      />
      <Target
        ac={ac}
        addCurse={setCurse}
        addDh={setDh}
        addSleep={() => setSleep((prev) => !prev)}
        computedAc={computedAc}
        sleep={sleep}
        resetFields={resetFields}
        curse={curse}
        dh={dh}
        setAc={setAc}
      />

      <Output
        vita={vita.replace(/\D/g, '')}
        mana={mana.replace(/\D/g, '')}
        desiredVita={desiredVita.replace(/\D/g, '')}
        desiredMana={desiredMana.replace(/\D/g, '')}
        ac={computedAc}
        withSleep={withSleep}
      />
      <React.Fragment>
        <h3 style={{ textDecoration: 'underline', marginTop: '20px' }} className={'title'}>
          {'Reference'}
        </h3>
        <References />
      </React.Fragment>
    </div>
  )
}
