import React from "react";
import references from './referenceData.json'

export default function References() {
  const mapReferences = (references) => {
    return references.map(({ caveName, min, max, ac }) => {
          return (<tr key={caveName}>
            <td>{caveName}</td>
            <td>{min}</td>
            <td>{max}</td>
            <td>{ac}</td>
          </tr>)
    });
  };
  return (
    <div className="cave-stats">
      <table>
        <tbody>
          <tr>
            <th>Cave[Room]</th>
            <th>Min Vita</th>
            <th>Max Vita</th>
            <th>Base AC</th>
          </tr>
          {mapReferences(references)}
        </tbody>
      </table>
    </div>
  );
}
