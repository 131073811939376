import React from 'react'

export default function Target({
  ac,
  addSleep,
  addCurse,
  addDh,
  dh,
  resetFields,
  curse,
  sleep,
  setAc,
  computedAc,
}) {
  return (
    <div className="target">
      <div
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
        className="AC"
      >
        <div style={{ marginBottom: 10 }}>
          <h5 style={{ color: 'aliceblue', textDecoration: 'underline', marginRight: 30 }}>{'Debuffs'}</h5>
        </div>
        <div className="checkboxes">
          <div className="sc-box">
            <input type="checkbox" checked={sleep} onChange={addSleep} />
            <h5 className={'checkbox-label'}>{'Sleep'}</h5>
          </div>
          <div>
            <div className="sc-box">
              <input
                type="checkbox"
                checked={curse['snare']}
                onChange={() =>
                  addCurse((prev) => {
                    return { snare: !prev['snare'], vex: false, sc: false, suppress: false }
                  })
                }
              />
              <h5 className={'checkbox-label'}>{'Snare'}</h5>
            </div>
            <div className="sc-box">
              <input
                type="checkbox"
                checked={curse['vex']}
                onChange={() =>
                  addCurse((prev) => {
                    return { snare: false, vex: !prev['vex'], sc: false, suppress: false }
                  })
                }
              />
              <h5 className={'checkbox-label'}>{'Vex/Sa Rogue Curse'}</h5>
            </div>
            <div className="sc-box">
              <input
                type="checkbox"
                checked={curse['sc']}
                onChange={() =>
                  addCurse((prev) => {
                    return { snare: false, vex: false, sc: !prev['sc'], suppress: false }
                  })
                }
              />
              <h5 className={'checkbox-label'}>{'Scourge'}</h5>
            </div>
            <div className="sc-box">
              <input
                type="checkbox"
                checked={curse['suppress']}
                onChange={() =>
                  addCurse((prev) => {
                    return { snare: false, vex: false, sc: false, suppress: !prev['suppress'] }
                  })
                }
              />
              <h5 className={'checkbox-label'}>{'Suppress'}</h5>
            </div>
          </div>
          <div>
            <div className="sc-box">
              <input
                type="checkbox"
                checked={dh['ee']}
                onChange={() =>
                  addDh((prev) => {
                    return { ee: !prev['ee'], sam: false, sa: false }
                  })
                }
              />
              <h5 className={'checkbox-label'}>{'Ee DH'}</h5>
            </div>
            <div className="sc-box">
              <input
                type="checkbox"
                checked={dh['sam']}
                onChange={() =>
                  addDh((prev) => {
                    return { ee: false, sam: !prev['sam'], sa: false }
                  })
                }
              />
              <h5 className={'checkbox-label'}>{'Sam DH'}</h5>
            </div>
            <div className="sc-box">
              <input
                type="checkbox"
                checked={dh['sa']}
                onChange={() =>
                  addDh((prev) => {
                    return { ee: false, sam: false, sa: !prev['sa'] }
                  })
                }
              />
              <h5 className={'checkbox-label'}>{'Sa DH'}</h5>
            </div>
          </div>
        </div>
        <div style={{ margin: 20, display: 'flex' }}>
          <div style={{ marginRight: 30 }}>
            <h5 className={'title'}>{"Target's Base AC"}</h5>
            <input
              style={{ width: 50 }}
              value={ac}
              onChange={(e) => {
                console.log(Number(e.target.value) < -150)

                if (e && e.target && e.target.value && Number(e.target.value) < -150) return setAc(-150)

                setAc(e.target.value.replace(/[^\d\-,]+/g, ''))
              }}
            />
          </div>
          <div>
            <h5 style={{ textDecoration: 'underline' }} className={'title'}>
              {'Adjusted AC'}
            </h5>
            <h2 className="title">{computedAc}</h2>
          </div>
          <button style={{ height: 28, marginLeft: 30 }} onClick={resetFields}>
            {'Reset'}
          </button>
        </div>
      </div>
    </div>
  )
}
