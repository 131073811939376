import React from "react";

export default function Mage({
  mana,
  desiredMana,
  withAc,
  withSleep,
  vita, desiredVita
}) {
  return (
    <div className="class">
      <table>
        <tbody className="spells">
          <tr>
            <td />
            <td>
              <h5 className={"title"}>Poet</h5>
            </td>
          </tr>
          <tr>
            <th>Spell</th>
            <th>Current</th>
            <th>Desired</th>
          </tr>
          <tr className="first-spell">
            <td>Retribution</td>
            <td>
              {Math.floor(
                withSleep() * (withAc * (mana * 0.3375)),
              ).toLocaleString()}
            </td>
            <td>
              {Math.floor(
                withSleep() * (withAc * (desiredMana * 0.3375)),
              ).toLocaleString()}
            </td>
          </tr>
          <tr style={{color: 'lightseagreen'}}>
            <td>Restore</td>
            <td>
              {Math.floor(
                (mana * 1.5),
              ).toLocaleString()}
            </td>
            <td>
              {Math.floor(
                (desiredMana * 1.5),
              ).toLocaleString()}
            </td>
          </tr>
          <tr>
            <td />
            <td>
              <h5 style={{marginTop: 50}} className={"title"}>Misc</h5>
            </td>
          </tr>
          <tr className="first-spell">
            <td>Ilbon Knife</td>
            <td>
              {Math.floor(
                withSleep() * (withAc * (vita * 1.75)),
              ).toLocaleString()}
            </td>
            <td>
              {Math.floor(
                withSleep() * (withAc * (desiredVita * 1.75)),
              ).toLocaleString()}
            </td>
          </tr>
          <tr className="second-spell">
            <td>Fate's blade</td>
            <td>
              {Math.floor(
                withSleep() * (withAc * (mana * 0.0245)),
              ).toLocaleString()}
            </td>
            <td>
              {Math.floor(
                withSleep() * (withAc * (desiredMana * 0.0245)),
              ).toLocaleString()}
            </td>
          </tr>
          <tr className="third-spell">
            <td>Throwing Axe</td>
            <td>
              {Math.floor(
                withSleep() * (withAc * (vita * 0.5 + mana * 0.5)),
              ).toLocaleString()}
            </td>
            <td>
              {Math.floor(
                withSleep() * (withAc * (desiredVita * 0.5 + desiredMana * 0.5)),
              ).toLocaleString()}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
